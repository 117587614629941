import { useRef } from "react";
import { useInView } from "framer-motion";
import styled from "styled-components";
//import "./shared.css";

function Section({ children, column }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  const numVariable = ((column / 2) * 0.9).toString() + "s";
  const variable = ` ${numVariable} cubic-bezier(0.17, 0.55, 0.55, 1) 0s`;
  return (
    <section className="test" ref={ref}>
      <span
        className="test"
        style={{
          transform: isInView ? "none" : "translateY(-200px)",
          // opacity: isInView ? 1 : 0,
          transition: variable,
        }}
      >
        {children}
      </span>
    </section>
  );
}

export default function Reveal({ card, column }) {
  return (
    <Wrapper>
      <Section column={column}>{card}</Section>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  section.test {
    border: none;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    padding: 0px;
  }

  section.test span.test {
    width: 100%;
    text-align: center;
  }
`;
