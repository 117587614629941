import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import EditDelete from "../components/shared/edit-delete";
import { LoggedIn } from "../utils/helper";
import styled from "styled-components";

const FAQ = () => {
  const userLoggedIn = LoggedIn();
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const URL = process.env.REACT_APP_API_URL;
        const res = await axios.get(`${URL}/posts?section=faq`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]); //

  return (
    <Wrapper>
      <div className="posts">
        {posts.map((post) => (
          <div className="post" key={post.id}>
            {post.img ? (
              <div className="img">
                <img src={`${STATIC_URL}/${post.img}`} alt={post.title} />
              </div>
            ) : null}
            <div className="content">
              {userLoggedIn && <EditDelete post={post} />}
              <h3>{post.title}</h3>
              <h4
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(post.desc),
                }}
              ></h4>
              {post.more ? (
                <Link to={`/more/${post.id}`}>
                  <h4>Read more ...</h4>
                </Link>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: "Varela", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  margin-bottom: 50px;
  margin-left: 10%;
  margin-right: 5%;

  .posts {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .post {
      display: flex;
      border: solid var(--main-bg-color) 4px;
      padding: 5px;

      .img {
        flex: 1;
        position: relative;

        img {
          width: 60%;
          max-height: 300;
        }
      }

      .content {
        flex: 3;
        flex-direction: column;
        justify-content: space-between;

        h1 {
          font-size: 32px;
        }

        p {
          font-size: 18px;
        }

        button {
          width: max-content;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          background-color: white;
          border: 1px solid teal;
          color: teal;

          &:hover {
            border: 1px solid white;
            background-color: var(--main-bg-color);
            color: black;
          }
        }
      }
    }
  }
`;


export default FAQ;
