import { useState, useEffect } from "react";
import axios from "axios";
import RadioItem from "./radioItem";
import "./radioGroup.css";

export default function RadioContainer({ change, defaultVal }) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const URL = process.env.REACT_APP_API_URL;
      try {
        const res = await axios.get(`${URL}/posts/sections`);
        setSections(res.data);
        console.log("data", res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="radioContainer">
      <h1 className="radioTitle">Section:</h1>
      {sections?.map((item) => (
        <div className="radioItem" key={item.id}>
          <RadioItem
            key={item.id}
            change={change}
            currentVal={defaultVal}
            item={item}
          />
        </div>
      ))}
    </div>
  );
}
