import { useState } from "react";
import "./radioGroup.css";

export default function RadioItem({ change, currentVal, item }) {
  const [section, setSection] = useState(currentVal);
  const itemVal = item.name; // use currentVal??
  const title = item.radio_title;
  const checked = currentVal ? currentVal === itemVal : item.default_yn === 1;

  const changeHandler = (val) => {
    setSection(val);
    change(val);
  };

  return (
    <div>
      <input
        type="radio"
        checked={checked}
        name="section"
        value={itemVal}
        id={itemVal}
        onChange={(e) => changeHandler(e.target.value)}
      />
      <label htmlFor={itemVal}>{title}</label>
    </div>
  );
}
