import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, Link } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./navbar.css";
import Search from "../search";

const Navbar = () => {
  const [data, setData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const URL = process.env.REACT_APP_API_URL;
      try {
        const res = await axios.get(`${URL}/posts/sections?layout=menu`);
        setData(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []);

  return (
    <header className="header">
      <nav className="nav container">
        <NavLink to="/" className="nav__logo"></NavLink>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav-menu"
        >
          <ul className="nav__list">
            {data &&
              data.map((item) => (
                <li key={item.id} className="nav__item">
                  {item.layout === "search" ? (
                    <Search
                      className="nav__input"
                      onClick={closeMenuOnMobile}
                    />
                  ) : item.layout === "url" ? (
                    <a className="nav__link" href={item.url}>
                      {item.nav_title}
                    </a>
                  ) : (
                    <NavLink
                      className="nav__link"
                      onClick={closeMenuOnMobile}
                      reloadDocument
                      to={item.menu_path}
                    >
                      {item.nav_title}
                    </NavLink>
                  )}
                </li>
              ))}
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
