import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import "./auth.css";

const Register = () => {
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const URL = process.env.REACT_APP_API_URL;
      const url = URL + "/auth/register";
      const res = await axios.post(url, inputs);
      console.log("res from handleSubmit:", res);
      setError("");
      navigate("/login");
    } catch (err) {
      console.log("res from handleSubmit:", err.response.data);
      setError(err.response.data);
    }
  };

  return (
    <div className="auth">
      <h1>Register</h1>
      <form action="">
        <input
          type="text"
          placeholder="username"
          name="username"
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="email"
          name="email"
          onChange={handleChange}
        />
        <input
          type="text"
          placeholder="password"
          name="password"
          onChange={handleChange}
        />
        <button onClick={handleSubmit}>Submit</button>
        <span>
          <p>{error}</p>
          Do you have an account?
          <Link to="/login"> Login</Link>
        </span>
      </form>
    </div>
  );
};

export default Register;
