import "./uploadFile.css";

export default function UploadFile({ file, handleFile }) {
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  return (
    <div className="uploadContainer">
      {/* <h1 className="radioTitle">Image:</h1> */}
      {file ? (
        <img
          src={`${STATIC_URL}/${file}`}
          alt=""
         
        />
      ) : null}

      <div>
        <input
          type="file"
          id="img"
          name="img"
          style={{ display: "none" }}
          //imagename={file.name}
          //accept="image/*"
          multiple={false}
          onChange={handleFile}
        />
        <label className="file" htmlFor="img">
          Upload Image
        </label>
      </div>
    </div>
  );
}
