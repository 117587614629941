import { useLocation, useNavigate, Navigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import RadioContainer from "../components/radioContainer";
import UploadFile from "../components/uploadFile.js";
import { useContext } from "react";
import { AuthContext } from "../context/authContext";
import styled from "styled-components";

const Write = () => {
  const OWNER = process.env.REACT_APP_OWNER;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [value, setValue] = useState(state?.desc || "");
  const [more, setMore] = useState(state?.more || "");
  const [title, setTitle] = useState(state?.title || "");
  const [file, setFile] = useState(state?.img || "");
  const [header_yn, setHeaderYN] = useState(state?.header_yn || "0");
  const [section, setSection] = useState(state?.section || "blog");
  //const STATIC_URL = process.env.REACT_APP_STATIC_URL;

  useEffect(() => {
    const fetchData = () => {
      try {
        if (state && state.title === "" && title !== "") {
          window.location.reload();
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [location.pathname]);

  const { token, loading } = useContext(AuthContext);
  if (loading) {
    return null;
  }

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSectionState = (val) => {
    setSection(val);
  };

  const URL = process.env.REACT_APP_API_URL;
  const upload = async () => {
    const upload_url = URL + "/upload";
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(upload_url, formData, {
        headers: { "Access-Control-Allow-Origin": "*" },
      });
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const postUrl = URL + "/posts";
    // state?.desc || ""
    const putUrl = `${postUrl}/${state?.id || 0}`;
    const imgUrl = file ? await upload() : null;
    try {
      state // .id
        ? await axios.put(putUrl, {
            title,
            desc: value,
            more: more ? more : "",
            section,
            img: imgUrl ? imgUrl : file,
            header_yn: header_yn,
          })
        : await axios.post(postUrl, {
            title,
            desc: value,
            more,
            section,
            img: imgUrl ? imgUrl : file,
            header_yn: header_yn ? header_yn : 0,
            timestamp: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
          });
      navigate(`/`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Wrapper className="add">
      <div className="content">
        <h4>Title:</h4>
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <h4>Article:</h4>
        <div className="editorContainer">
          <ReactQuill
            // modules={modules}
            className="editor"
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
        <h4>Article, Page 2:</h4>
        <div className="editorContainer">
          <ReactQuill
            // modules={modules}
            className="editor"
            theme="snow"
            value={more}
            onChange={setMore}
          />
        </div>
      </div>
      <div className="menu">
        <RadioContainer change={handleSectionState} defaultVal={section} />

        <UploadFile file={file} handleFile={handleFile} />
        {OWNER === "EPC" && (
          <div className="headerYN">
            <label className="header-label" htmlFor="header_yn">
              Header:
            </label>
            <input
              type="checkbox"
              name="header_yn"
              id="header_yn"
              checked={header_yn === 1}
              onChange={() => {
                setHeaderYN(header_yn === 1 ? 0 : 1);
              }}
            />
          </div>
        )}
        <div className="buttons">
          <button onClick={handleClick}>Publish</button>
        </div>
      </div>
      <input
        type="text"
        name="img"
        id="img"
        style={{ display: "none" }}
        defaultValue={file}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    width: 100px;
  }

  border: 10px solid lightgray;
  margin: 10%;
  display: flex;
  gap: 20px;

  margin-top: 20px;
  display: flex;
  gap: 20px;

  .content {
    flex: 4;
    border: 5px solid lightgray;
    padding: 10px;

    margin: 2px;
    font-size: 12px;
    color: #555;

    h4 {
      font-size: 20px;
      margin: 2px;
    }

    input {
      padding: 10px;
      width: 95%;
      border: 1px solid lightgray;
    }

    .editorContainer {
      height: 300px;
      overflow: scroll;
      border: 1px solid lightgray;

      .editor {
        height: 100%;
        border: none;
      }
    }
  }

  .menu {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .buttons {
      display: flex;
      justify-content: space-between;

      button {
        width: max-content;
        padding: 7.5px 15px;
        border: none;
        cursor: pointer;
        color: teal;
        background-color: white;
        border: 1px solid teal;
        font-weight: 800;
        font-size: 20px;
      }
    }
  }
  .headerYN label {
    font-size: 16px;
    color: teal;
    margin: 2px;
    margin-right: 4px;
  }
`;

export default Write;
