import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import EditDelete from "../../components/shared/edit-delete";
import DOMPurify from "dompurify";
import { useInView } from "framer-motion";
import "./carousel.css";

const Carousel = () => {
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const URL = process.env.REACT_APP_API_URL;
        const res = await axios.get(`${URL}/posts?section=intro`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [cat]); //

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  // console.log("isInView", isInView);

  return (
    <div className="intro-carousel">
      <div id="carouselMarker" className="carousel slide" data-bs-ride="true">
               <div className="carousel-indicators">
          {posts.map((post, index) => (
            <button
              key={post.id}
              type="button"
              data-bs-target="#carouselMarker"
              data-bs-slide-to={index}
              className={`${index === 0 ? "active" : ""}`}
              aria-current="true"
              aria-label={`Slide ${index + 1}`}
            ></button>
          ))}
        </div> 
        <div className="carousel-inner">
          {posts.map((post, index) => (
            <div
              key={post.id}
              className={`carousel-item ${index === 0 ? "active" : ""}`}
            >
              <img
                height="100%"
                src={`${STATIC_URL}/${post.img}`}
                className="d-block w-100"
                alt={post.title}
              />
              <div className="carousel-caption d-md-block">
                <h1
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(post.desc),
                  }}
                />
                <EditDelete post={post} />
              </div>
            </div>
          ))}
        </div>
      </div>
    {/*   <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselMarker"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselMarker"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  );
};
export default Carousel;
