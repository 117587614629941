import { Link } from "react-router-dom";
import Reveal from "./reveal";
import styled from "styled-components";

const ScheduleLink = () => {
  return (
    <>
      <Reveal
        card={
          <Wrapper>
            <section id="call-to-action">
              <div className="container text-center">
                <h3>*SCHEDULE A FREE ESTIMATE*</h3>
                <Link className="cta-btn" id="" to="/contact">
                  Call To Action
                </Link>
              </div>
            </section>
          </Wrapper>
        }
      />
    </>
  );
};

const Wrapper = styled.div`
  /* Call To Action Section
--------------------------------*/
  #call-to-action {
    background-size: cover;
    padding: 30px 0;
    background: #96bfe0;
  }

  #call-to-action h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }

  #call-to-action .cta-btn {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 25px;
    transition: 0.5s;
    margin-top: 10px;
    border: 2px solid #fff;
    color: #fff;
  }

  #call-to-action .cta-btn:hover {
    background: #18d26e;
    border: 2px solid #18d26e;
  }
  div.card div.click-here {
    color: red;
    background-color: red;
    font-size: 24px;
  }
`;
export default ScheduleLink;
