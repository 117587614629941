import { useState, useEffect } from "react";
import axios from "axios";
import ColumnContainer from "../shared/columnContainer";

const Team = () => {
  const [posts, setPosts] = useState([]);
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const URL = process.env.REACT_APP_API_URL;

  //const axiosPath = `/posts/columns/team`;
  const axiosPath = URL + `/posts?section=team`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(axiosPath);
        setPosts(res.data);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]);

  return <ColumnContainer id="team" posts={posts} />;
};

export default Team;
