import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import { LoggedIn } from "../../utils/helper";
import React from "react";
import Reveal from "./reveal";
import styled from "styled-components";
import "./columnContainer.css";

const ColumnContainer = ({ posts, id, expansionLink }) => {
  const userLoggedIn = LoggedIn();
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const [header, ...details] = posts;
  return (
    <div id={id} className="columnContainer">
      {/* HEADING OF SECTION */}
      <div className="heading">
        <Reveal
          column={1}
          card={
            <div className="card">
              <div className="card-body">
                {userLoggedIn && (
                  <Link to={`/single/${header && header.id}`}>Edit</Link>
                )}
                {header && header.img && (
                  <div className="teamImg">
                    <img
                      src={`${STATIC_URL}/${header.img}`}
                      alt={header && header.title}
                    />
                  </div>
                )}
                <div className="card-header">{header && header.title}</div>
                <div
                  className="card-text"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(header && header.desc),
                  }}
                ></div>
              </div>
            </div>
          }
        />
        {/* LINK TO EXPAND DETAIL (ADD LIST OF SERVICES) */}
        {expansionLink}
      </div>
      {/* DETAIL OF SECTION */}
      <div
        id={id === "services" ? "servicelist" : ""}
        className={id === "services" ? "detail collapse" : "detail"}
      >
        <div className="cards-container">
          {details.map((post, index) => (
            <div className="card-container" key={post.id}>
              <Reveal
                column={index}
                card={
                  <div className="card">
                    {post.id && (
                      <div className="card-body">
                        {userLoggedIn && (
                          <Link to={`/single/${post.id}`}>Edit</Link>
                        )}
                        {post.img && (
                          <div className="teamImg">
                            <img
                              src={`${STATIC_URL}/${post.img}`}
                              alt={post.title}
                            />
                          </div>
                        )}
                        <h2 className="card-header">{post.title}</h2>
                        <div
                          className="card-text"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(post.desc),
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ColumnContainer;
