import { useState, useEffect } from "react";
//import { Link } from "react-router-dom";
import axios from "axios";
import ColumnContainer from "../shared/columnContainer";
import Reveal from "../shared/reveal";
import styled from "styled-components";

const Services = () => {
  const [posts, setPosts] = useState([]);
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const URL = process.env.REACT_APP_API_URL;
  const axiosPath = URL + `/posts?section=services`; // `/posts/columns/about`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(axiosPath);
        setPosts(res.data);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]);

  return (
    <Wrapper>
      <ColumnContainer
        id="services"
        name="services"
        className="column-container"
        posts={posts}
        expansionLink={
          <Reveal
            card={
              <div className="card click-here" id="services" name="services">
                <a
                  className="btn btn-primary "
                  data-bs-toggle="collapse"
                  href="#servicelist"
                  role="button"
                  aria-expanded="false"
                  aria-controls="servicelist"
                >
                  <h3 className="click-here">
                    Click here to learn more about the services we offer
                  </h3>
                </a>
              </div>
            }
          />
        }
      />
    </Wrapper>
  );
};
const Wrapper = styled.div`
  .column-container {
    border: none;
  }
`;
export default Services;
