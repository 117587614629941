import { useState, useEffect } from "react";
import axios from "axios";
import Site from "../components/home/site";
import Carousel from "../components/home/carousel";
import About from "../components/home/about";
import Services from "../components/home/services";
import Team from "../components/home/team";
import ScheduleLink from "../components/shared/scheduleLink";

const Launch = () => {
  const section = "team";
  const [sectionData, setSectionData] = useState([]);
  const [posts, setPosts] = useState([]);
  const URL = process.env.REACT_APP_API_URL;
  const sectionPath = URL + `/posts/section/${section}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(sectionPath);
        setSectionData(res.data[0]);
      } catch (err) {
        console.log("get Section err", err);
      }
    };
    fetchData();
  }, [sectionPath]);

  const axiosPath =
    sectionData && sectionData.layout === "column"
      ? `/posts/columns/${section}/`
      : `/posts?section=${section}/`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(URL + axiosPath);
        setPosts(res.data);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]);

  return (
    <main id="main">
      {/* <Site /> */}
      <Carousel />
      <About />
      <ScheduleLink />
      <Services />
      <ScheduleLink />
      <Team />
    </main>
  );
};

export default Launch;
