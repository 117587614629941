import { useState, useEffect } from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import DOMPurify from "dompurify";

const Site = () => {
  const [posts, setPosts] = useState([]);
  const data = posts[0];
  console.log("data", data);
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const imgSrc = data && `${STATIC_URL}/${data.img}`;
  const URL = process.env.REACT_APP_API_URL;

  const axiosPath = `/posts?section=site`; // `/posts/columns/about`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(URL + axiosPath);
        setPosts(res.data);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]);

  return (
    <Wrapper className="top">
      {data && data.header_yn === 1 && (
        <div className="header">
          {data && (
            <div className="topImg">
              <img className="topImg" src={imgSrc} alt={data.title} />
            </div>
          )}
          {data && (
            <div className="centerTitle">
              <div className="topTitleDiv">
                <NavLink className="link topTitle" to="/">
                  <h2
                    className="topTitle"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data.title),
                    }}
                  ></h2>
                </NavLink>
                <h4
                  className="topSubtitle"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data.desc),
                  }}
                ></h4>
              </div>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};
export default Site;
const Wrapper = styled.div`
  .header {
    font-family: "Varela", sans-serif;
    background-color: var(--main-bg-color);

    width: 100%;
    height: 3vh;
    position: sticky;
    top: 0;
    z-index: 999;
    /* bottom: 10px; */

    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 0px;

    .topImg {
      margin: 0;

      img.topImg {
        vertical-align: top;
        width: 20vw;
      }
    }
    .centerTitle {
      display: flex;
      flex: 2;

      .topTitleDiv {
        cursor: pointer;
        margin: 8px;
        color: red;
        display: flex;
        flex: 1;
        flex-direction: column;

        .topTitle {
          text-align: center;
          font-size: 36px;
          font-weight: 600;
          color: black;
        }

        .topSubtitle {
          text-align: center;
          font-size: 32px;
          font-weight: 500;
          color: rgb(58, 57, 57);
        }
      }
    }

    .menu {
      display: flex;
      justify-content: flex-end;
      align-items: right;
      margin: 0;
      padding: 0;
    }
  }
`;
