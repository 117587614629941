import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import EditDelete from "../components/shared/edit-delete";
import styled from "styled-components";
//import Image from "../components/image";

const Single = ({ section }) => {
  const location = useLocation();
  const [post, setPost] = useState([]);
  // const section = location.pathname.split("/")[1];
  const postId = location.pathname.split("/")[2];
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const axiosPath =
    section === "post"
      ? `/posts/${postId}`
      : section === "single"
      ? `/posts/single/${postId}`
      : section.length === 0
      ? "/posts?section=home"
      : `/posts?section=${section}`;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const URL = process.env.REACT_APP_API_URL;
        const res = await axios.get(URL + axiosPath);
        setPost(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [axiosPath]);

  const imgSrc = post.img ? `${STATIC_URL}/${post.img}` : null;

  return (
    <Wrapper>
      <div className="flex-container">
        <div className="blank"></div>
        {post && (
          <div className="post" key={post.id}>
            {post.img && (
              <div className={post.image_only_yn === 0 ? "img" : "imgOnly"}>
                <img src={imgSrc} alt={post.title} />
              </div>
            )}
            <EditDelete post={post} />

            {post.image_only_yn === 0 ? (
              <div className="content">
                <h1>{post.title}</h1>

                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(post.desc),
                  }}
                ></p>
                {post.more ? (
                  <Link state={post} to={`/more/${post.id}`}>
                    <h4>Read more ...</h4>
                  </Link>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
        <div className="blank"></div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .flex-container {
    display: flex;
    flex-direction: row;
  }

  .blank {
    width: 20%;
  }

  .post {
    width: 60%;
    text-align: center;
  }
  div.img {
    img {
      margin-top: 10px;
      width: 40%;
    }
  }
`;
export default Single;
