import { useContext } from "react";
import { AuthContext } from "../context/authContext";

export function LoggedIn() {
  const { token, loading } = useContext(AuthContext);
  if (loading) {
    return null;
  }
  return token;
}
