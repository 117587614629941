import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import "./index.css";
import { Route, Routes, BrowserRouter, Outlet } from "react-router-dom";
//import Auth from "./components/auth";

import Blog from "./pages/Blog";
import FAQ from "./pages/FAQ";
import Page2 from "./pages/Page2";
import Single from "./pages/Single";
import Login from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";
import Register from "./pages/Auth/Register";
import Write from "./pages/Write";
import Contact from "./pages/Contact/Contact";
import Footer from "./components/shared/footer";
import Topbar from "./components/shared/topbar";

import Launch from "./pages/Launch";
import { AuthContextProvider } from "./context/authContext";

const Layout = () => {
  const URL = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
  const axiosPath = `/posts/site`; // `/posts/columns/about`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(URL + axiosPath);
        setData(res.data[0]);
        document.title = res.data[0].title;
      } catch (err) {
        console.log("get Site Info error", err);
      }
    };
    fetchData();
  }, [axiosPath]);
  console.log("siteInfo", data);
  return (
    <div className="container-fluid">
      <AuthContextProvider>
        <Topbar siteInfo={data} />
        <Outlet />
        <Footer siteInfo={data} />
      </AuthContextProvider>
    </div>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
const basename = process.env.REACT_APP_BASENAME_URL;
const OWNER = process.env.REACT_APP_OWNER;
console.log("OWNER", OWNER);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route basename={basename} path="/" element={<Layout />}>
          {OWNER === "EPC" && (
            <>
              {/* EPC, ONLY */}
              <Route path="/" element={<Launch section="all" />} />
              <Route path="/home" element={<Launch section="all" />} />
              <Route path="/about" element={<Launch />} />
              <Route
                path="/services/"
                forceRefresh={true}
                element={<Launch section="services" />}
              />
              <Route path="/launch/" element={<Launch section="all" />} />
            </>
          )}
          {OWNER === "SAM" && (
            <>
              {/* SAM, ONLY */}
              <Route path="/" element={<Single section="home" />} />
              <Route path="/home" element={<Single section="home" />} />
              <Route path="/gospel" element={<Single section="gospel" />} />
              <Route path="/about" element={<Single section="about" />} />
            </>
          )}
          {/* AUTHENTICATION - BOTH */}
          <Route path="/login/" element={<Login />} />
          <Route path="/logout/" element={<Logout />} />
          <Route path="/register/" element={<Register />} />
          {/* <Route path="/auth/" element={<Auth />} /> */}

          {/* OTHER PAGES - BOTH */}
          <Route path="/post/:id" element={<Single section="single" />} />
          <Route path="/single/:id" element={<Single section="single" />} />
          <Route path="/more/:id" element={<Page2 />} />
          <Route path="/write/" forceRefresh={true} element={<Write />} />
          <Route path="/edit/" element={<Write />} />
          <Route path="/blog/" forceRefresh={true} element={<Blog />} />
          <Route path="/faq/" forceRefresh={true} element={<FAQ />} />
          <Route path="/contact/" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
