// src/App.jsx
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Search() {
  const [searchItem, setSearchItem] = useState("");
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${URL}/posts?section=all`);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredPosts = posts.filter(
      (post) =>
        post.desc.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (post.more &&
          post.more.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setFilteredPosts(filteredPosts);
  };
  const clearSearchFilter = () => {
    setFilteredPosts([]);
    setSearchItem("");
  };
  return (
    <>
      <input
        type="text"
        value={searchItem}
        onChange={handleInputChange}
        placeholder="Type to search"
      />
      <div className="flexContainer">
        <ul>
          {searchItem &&
            filteredPosts &&
            filteredPosts.map((post) => (
              <li key={post.id}>
                <Link to={`/single/${post.id}`}>{post.title}</Link>
              </li>
            ))}
          {searchItem && <button onClick={clearSearchFilter}>Clear</button>}
        </ul>
      </div>
    </>
  );
}

export default Search;
