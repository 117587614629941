import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import styled from "styled-components";

import EditDelete from "../components/shared/edit-delete";

const Page2 = () => {
  const post = useLocation().state;

  return (
    <Wrapper>
      <div className="home">
        <div className="content">
          <EditDelete post={post} />

          {post.title && <h1>{post.title}</h1>}
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(post.more),
            }}
          ></p>
        </div>
      </div>
    </Wrapper>
  );
};

export default Page2;
const Wrapper = styled.div`
  margin-bottom: 50px;
  margin-left: 10%;
  margin-right: 5%;

  .posts {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;

    .post {
      display: flex;

      .img {
        flex: 1;
        position: relative;

        img {
          width: 60%;
          max-height: 300;
        }
      }

      .content {
        flex: 3;

        flex-direction: column;
        justify-content: space-between;

        h1 {
          font-size: 32px;
        }

        p {
          font-size: 18px;
        }

        button {
          width: max-content;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          background-color: white;
          border: 1px solid teal;
          color: teal;

          &:hover {
            border: 1px solid white;
            background-color: var(--main-bg-color);
            color: black;
          }
        }
      }
    }
  }
`;
