import { Link, NavLink } from "react-router-dom";
import DOMPurify from "dompurify";
import Navbar from "./Navbar/navbar";

// import { useState, useEffect } from "react";
// import axios from "axios";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import "./topbar.css";

export default function Topbar({ siteInfo }) {
  const location = useLocation();
  const isHome = location.pathname === "/" || location.pathname === "/home";
  /* In order to pass this to styled, must be a string */
  const ishome = isHome.toString();
  /*   const [data, setData] = useState([]);
  const axiosPath = `/posts/logo`; // `/posts/columns/about`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(URL + axiosPath);
        setData(res.data[0]);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]); */

  const data = siteInfo;
  const showCenterTitle = data.subTitle ? true : false;
  const showcentertitle = showCenterTitle.toString();

  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const imgSrc = STATIC_URL + "/" + data.logo;
  return (
    <div ishome={showcentertitle} className="top">
      {data.subtitle && <Navbar className="menu" />}
      <div className="titleBar">
        <NavLink className="topImg" to="/">
          <img src={imgSrc} alt="" />
        </NavLink>
        <div className="centerTitle">
          <div className="topTitleDiv">
            <NavLink className="link topTitle" to="/">
              <h2
                className="topTitle"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.title),
                }}
              ></h2>
            </NavLink>
            {data.subtitle && (
              <h4
                className="topSubtitle"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(data.subtitle),
                }}
              ></h4>
            )}
          </div>
        </div>
      </div>

      {!data.subtitle && <Navbar className="menu" />}
    </div>
  );
}
