import Edit from "../../img/edit.png";
import Delete from "../../img/delete.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LoggedIn } from "../../utils/helper";

const EditDelete = ({ post }) => {
  const userLoggedIn = LoggedIn();
  const navigate = useNavigate();
  if (!userLoggedIn) {
    return null;
  }
  const handleDelete = async () => {
    const URL = process.env.REACT_APP_API_URL;
    const section = post.section;

    try {
      //  const res = await axios.get(`${URL}/posts/sections`);
      await axios.delete(`${URL}/posts/${post.id}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      navigate(`/${section}/`);
    } catch (err) {
      console.log(err.response.status, err.response.data);
    }
  };

  return (
    <Wrapper>
      {
        <div className="edit-delete">
          <Link to={`/edit`} state={post}>
            <img src={Edit} alt="" />
          </Link>
          <img onClick={handleDelete} src={Delete} alt="" />
        </div>
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  img {
    width: 20px;
    height: 20px;
    margin-left: 20px;
    cursor: pointer;
    padding: 2px;
    background-color: lightgreen;
  }
`;
export default EditDelete;
