import { useLocation, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { LoggedIn } from "../../utils/helper";
import "./footer.css";

export default function Footer({ siteInfo }) {
  const userLoggedIn = LoggedIn();
  const location = useLocation();
  const isHome = location.pathname === "/" || location.pathname === "/home";
  /* In order to pass this to styled, must be a string */
  const ishome = isHome.toString();
  // const [data, setData] = useState([]);
  // const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const data = siteInfo;

  return (
    <footer>
      <div className="footer">
        <div className="footer-contact">
          <div className="contact-title">Contact Us</div>
          <div className="contact-info">
            <div className="phone">
              <strong>Phone: </strong>
              {data.phone}
              <a href={data.facebook_link} className="facebook">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </div>
            <div className="email">
              <strong>Email: </strong>
              {data.email}
            </div>
            {data.mailing_address && (
              <div className="poaddress">
                <strong>Mailing Address:</strong>
                <br />
                {data.mailing_address} <br />
                {data.mailing_city_line}
              </div>
            )}
          </div>
        </div>
        <div className="footer-location">
          <div className="contact-title">Location</div>
          <div className="contact-info">
            <div className="location">
              {data.location_address} <br />
              {data.location_city_line}
            </div>

            <div className="authContainer">
              {userLoggedIn ? (
                <div className="auth-2">
                  <Link to="/register" className="auth-link">
                    Register
                  </Link>
                  <Link to="/logout" className="auth-link">
                    Log Out
                  </Link>
                  <Link to="/write">Write</Link>
                </div>
              ) : (
                <div className="auth-1">
                  <Link to="/login">Log In</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

/* const Wrapper = styled.div`
  font-family: "Varela", sans-serif;
  background-color: var(--main-bg-color);

  @media (max-width: 768px) {
    div.footer {
      width: 100%;
      display: flex;
      flex: 2;
      flex-flow: row;
      background: pink;
    }
    @media (min-width: 768px) {
  div.footer {
    width: 100%;
    display: flex;
    flex: 2;
      flex-flow: column;
    background: orange;
    }
    .footer-contact {
      width: 50%;
      display: flex;
      flex: 2;
      flex-flow: row;
      background: green;
      height: auto;

      .contact-title {
        display: flex;
        flex-position: center;
        margin: 10px;
        font-size: 18px;
        font-weight: bold;
        width: 20%;
      }
      .contact-info {
        display: flex;
        margin: 10px;
        width: 80%;
        padding: 10;
        font-size: 18px;
        height: 100px;

        .facebook {
          margin-left: 10px;
          min-height: 4.5vh;
          max-height: 4.5vh;
          padding: 0;
          border: none;
        }
      }
    }
    .footer-location {
      width: 50%;
      display: flex;
      flex: 2;
      flex-flow: row;
      background: yellow;
      height: 100px;

      .contact-title {
        flex-position: center;
        margin: 10px;
        width: 20%;
        font-size: 18px;
        font-weight: bold;
      }
      .contact-info {
        flex: 2;
        flex-flow: column;
        margin: 10px;
        width: 80%;
        font-size: 18px;
        padding: 10;
        font-size: 24px;
      }

      .authContainer {
        display: flex;
        flex: 1;
        margin-top: 10px;
        min-height: 4.5vh;
        max-height: 4.5vh;
        padding: 0;
        border: none;

        a {
          height: 4.5vh;
          padding: 0;
          border: none;
        }

        div.auth-1 {
          display: flex;
          flex: 1;
          min-height: 4.5vh;
          max-height: 4.5vh;
          padding: 0;
          border: none;
        }
        div.auth-2 {
          display: flex;
          flex: 3;
          flex-flow: row;
          margin-right: 5px;

          min-height: 4.5vh;
          max-height: 4.5vh;
          padding: 0;
          border: none;

          .auth-link {
            margin-right: 20px;
          }
        }
      }
    }
  }
`; */
