import { useState, useEffect } from "react";
import axios from "axios";
import ColumnContainer from "../shared/columnContainer";

const About = () => {
  const [posts, setPosts] = useState([]);
  const STATIC_URL = process.env.REACT_APP_STATIC_URL;
  const URL = process.env.REACT_APP_API_URL;

  const axiosPath = `/posts?section=about`; // `/posts/columns/about`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(URL + axiosPath);
        setPosts(res.data);
      } catch (err) {
        console.log("get Posts error", err);
      }
    };
    fetchData();
  }, [axiosPath]);

  return <ColumnContainer id="about" posts={posts} />;
};

export default About;
